import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Jwt = () => {
  const navigate = useNavigate();
  const [, setIsTokenValid] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    const checkTokenValidity = async (token) => {
      try {
        const response = await fetch("https://rbdw-api.dekkerdev.com/token", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Set the JWT token in the request headers
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setIsTokenValid(data.isValid);
        } else {
          throw new Error("Unauthorized"); // Throw an error to trigger the catch block
        }
      } catch (error) {
        localStorage.removeItem("jwtToken");
        navigate("/login"); // Replace 'navigate' with your actual navigation function or logic
      }
    };

    // Assuming you have the JWT stored in state or a variable called 'jwtToken'
    checkTokenValidity(jwtToken);
  }, []);

  return <></>;
};

export default Jwt;
