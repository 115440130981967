import React, { useState } from "react";
import Jwt from "../auth/jwt/jwt";
import "./push.css";

const NotificationPage = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = async (event) => {
    const jwtToken = localStorage.getItem("jwtToken");
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await fetch(
        "https://rbdw-api.dekkerdev.com/send-notification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken, // Replace with your actual JWT token
          },
          body: JSON.stringify({ title, body }),
        }
      );

      if (response.ok) {
        const message = await response.text();
        setSuccessMessage(message);
      } else {
        const error = await response.text();
        setErrorMessage(error);
      }
    } catch (error) {
      console.error("Notification error:", error);
      setErrorMessage("An unexpected error occurred");
    }
  };

  return (
    <>
      <Jwt />
      <div className="notification-container-over">
        <div className="notification-container">
          <h2>Send Notification</h2>
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleTitleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="body">Body:</label>
              <input
                type="text"
                id="body"
                value={body}
                onChange={handleBodyChange}
              />
            </div>
            <button type="submit">Send Notification</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
